<template>
  <v-container>
    <perseu-card :title="`Usuários específicos por e-mail: ${planName}`">
      <template #title-left>
        <back-button @click="$router.go(-1)" />
      </template>
      <template #content>
        <v-row justify="center">
          <v-col cols="10">
            <v-form ref="form" @submit.prevent="submitNewEmail">
              <v-text-field
                label="Inserir novo e-mail ao plano"
                v-model="newEmail"
                :rules="[$rules.required, $rules.email]"
              />
            </v-form>
          </v-col>
          <v-col cols="2">
            <v-btn color="secondary" @click="submitNewEmail" large>
              <v-icon class="mr-2">check</v-icon>
              Enviar
            </v-btn>
          </v-col>
        </v-row>
        <v-list>
          <v-list-item
            v-for="user in users"
            :key="user.id"
            class="border-bottom-grey"
          >
            <v-list-item-icon>
              <v-icon size="50">contact_mail</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ user.email }}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <table-action-button
                @click="deleteUserEmail(user.id)"
                icon="delete"
                text="Remover"
                color="error"
              />
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </template>
    </perseu-card>
  </v-container>
</template>

<script>
import {
  addUserEmailToPlan,
  deleteUserEmailOfPlan,
  findAllUsersEmailByPlanId,
} from "@/services/paypal-service";

export default {
  name: "UsersEmailSection",
  data: () => ({
    newEmail: "",
    users: [],
  }),
  computed: {
    planName() {
      return this.$route.query.planName;
    },
    planId() {
      return this.$route.query.planId;
    },
  },
  created() {
    this.fillData();
  },
  methods: {
    async fillData() {
      try {
        this.$store.dispatch("loading/openDialog");
        const { data } = await findAllUsersEmailByPlanId(this.planId);
        this.users = data;
      } catch (e) {
        this.$errorHandler(e);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    async submitNewEmail() {
      try {
        this.$store.dispatch("loading/openDialog");
        if (!this.$refs.form.validate()) return;
        const { data } = await addUserEmailToPlan({
          email: this.newEmail,
          planId: this.planId,
        });
        this.users.push(data);
        this.$refs.form.reset();
      } catch (e) {
        this.$errorHandler(e);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    async deleteUserEmail(id) {
      try {
        this.$store.dispatch("loading/openDialog");
        if (!confirm("Confirmar remoção do usuário?")) return;
        await deleteUserEmailOfPlan(id);
        const deleteIndex = this.users.findIndex((user) => user.id === id);
        this.users.splice(deleteIndex, 1);
        this.$toasted.global.success({
          message: "E-mail removido com sucesso",
        });
      } catch (e) {
        this.$errorHandler(e);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
  },
};
</script>

<style scoped></style>
